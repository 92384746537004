.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #a4c7dc;
}

.login-left {
    width: 45%;
    display: flex;
    justify-content: center;
}

.login-right {
    width: 65%;
}

.login-box {
    width: 580px;
    background: #fff;
    height: 700px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-box-logo {
    display: block;
    margin: 0 auto 40px auto;
}

.login-box-inner {
    width: 400px;
}

.login-box-inner h2 {
    font-size: 24px;
    margin: 0;
    font-weight: 700;
    color: var(--color-primary);
}

.login-box-inner h3 {
    font-size: 14px;
    margin-bottom: 50px;
    font-weight: 400;
}

.login-box-inner label {
    font-weight: 700;
}

.login-box-inner input.default {
    background-color: #eff2f3;
    height: 52px;
    width: 100%;
    /* border: none; */
}

.login-box-inner .code {
    margin-bottom: 30px;
}

.login-box-inner .code .character {
    background-color: #eff2f3;
    border: 1px solid #d9d9d9;
    font-size: 20px;
}

.login-box-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1170px) {
    .login-left {
        width: 100%;
    }

    .login-right {
        display: none;
    }
}

.ReactPasswordStrength {
    border: none !important;
    overflow: hidden;
    border-radius: 4px;
}

.ReactPasswordStrength-strength-desc {
    /* color: transparent;
    font-style: italic;
    padding: 0;
    position: relative !important;
    display: block;
    top: inherit !important;
    right: inherit;
    text-align: right;
    transition: color 250ms ease-in-out;
    width: 15%;
    margin-top: 5px;
    border: 1px solid #000;
    font-weight: 700; */
    font-size: 12px;
    width: 25% !important;
    top: 6px !important;
    font-weight: 700;
    font-style: normal !important;
}

.ReactPasswordStrength-input {
    border: 1px solid #d9d9d9 !important;
}

.ReactPasswordStrength-strength-bar {
    top: 0 !important;
    right: 0 !important;
}

.ReactPasswordStrength-input::-webkit-input-placeholder {
    font-size: 13px
}