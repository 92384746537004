/* Loaging */
.loading-plans{
    width: 100%;
    height: 200px;
    background:#fff;
    border-radius: 10px;
    margin-top: 20px;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,.07);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,.07);
}
.modal-plan-body{
    padding: 0 20px;
}

.modal-plan-body .wallet-info img{
    border-radius: 10px;
}

.list-plans{
    padding: 0 40px;
}   
.wallet-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.wallet-info button{
    margin-top: 10px;
    border-radius: 10px;
    /* opacity: 0;
    visibility: hidden;
    transition: .2s; */
}
.wallet-info:hover button{
    opacity: 1;
    visibility: visible;
}
.edit-form{
    border-radius: 10px;
    border: 1px dashed #d9d9d9;
    padding: 20px;
    width: 600px;
    display: block;
    margin: 0 auto;
}

/* .edit-form .ant-upload.ant-upload-select-picture-card{
    width: 100%;
} */

.edit-wallet-switch{
    display: flex;
    margin-bottom: 10px;
}
.edit-wallet-swit span{
    font-size: 10px;
    margin-right: 10px;
}