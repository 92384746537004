.modal-customer .ant-modal-body {
    padding: 0px;
}

.modal-customer .ant-spin-blur {
    opacity: 0.1;
}

.modal-customer-header {
    background: rgba(var(--primary), 0.09);
    width: 100%;
    height: 115px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    padding: 35px 30px 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-customer-header-data {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
}

.modal-customer-header-data p {
    margin-right: 25px;
    position: relative;
}

.modal-customer-header-data p b {
    display: block;
}

.modal-customer-header-data p .icon-notification {
    position: absolute;
    top: 24px;
    right: -50px;
    width: 30px;
    height: 30px;
    padding-top: 7px;
    /* border: none;
    background: #ffdf18;
    color: #000; */
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
}

.modal-customer-header button.green {
    background: #2dbf12;
    border-color: #30bf12;
}


.modal-customer-body {
    padding: 15px 15px 50px 15px;
}

.modal-customer-body h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
}

.modal-customer-body .ant-form-item {
    margin-bottom: 5px;
}

.modal-customer-body .col-resume,
.modal-customer-body .col-plan,
.modal-customer-body .col-historic {
    height: 600px;
    border-right: 1px solid rgba(0, 0, 0, .04);
    padding-left: 15px;
}

.modal-customer-body input {
    width: 90%;
    border-radius: 8px;
}

.modal-customer-body .col-resume p b,
.modal-customer-body .col-plan p b {
    display: block;
    color: #333;
}

.modal-customer-body .col-historic .data-retorno {
    border-radius: 7px;
    margin-top: 5px;
}


.modal-customer-body .col-historic .obs {
    /* height: 100px; */
    border-radius: 10px;
    margin-top: 5px;
}

.modal-customer-body .col-historic textarea.obs {
    height: 100px;
}

.modal-customer-body .col-plan .ant-card {
    background: rgba(0, 0, 0, 0.02)
}


.modal-customer-body .col-historic {
    border: none;
    overflow-y: scroll;
}


.speech-bubble {
    position: relative;
    background: rgba(var(--primary), 0.09);
    border-radius: 10px;
    padding: 20px;
}

.speech-bubble p {
    margin: 0px;
}

.speech-bubble p.obs {
    font-style: italic;
    line-height: 14px;
    font-size: 14px;
    opacity: 0.8;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgba(var(--primary), 0.09);
    border-top: 0;
    margin-left: -10px;
    margin-top: -10px;
    left: 35px;
}

.pop-over-edit-customer {
    width: 300px !important;
}

.lives-line {
    display: flex;
    align-items: center;
    margin-top: -10px;
}

.lives-line .buttons {
    display: flex;
}

.lives-line button {
    margin-left: 5px;
}

.lives-line button.excel {
    margin-left: 10px;
}

.lives-line button.delete-file {
    font-size: 10px;
    display: block
}

/* .modal-customer button{
    border-radius: 20px;
} */


.edit-city {
    border-radius: 10px;
}

.edit-state {
    border-radius: 10px;
    margin-left: 10px;
}


.modal-customer-body .ant-select-selector {
    border-radius: 8px !important;
}