.list-plan-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
    padding: 10px 20px;
    margin-bottom: 15px;
}
.list-plan-data{

}
.list-plan-data p{
    margin: 0px
}
.list-plan-data p.title{
    font-weight: 700;
    font-size: 16px;
}
.list-plan-data p.price{
    
}
.list-plan-switch{
    display: flex;
    align-items: center;
}
.list-plan-switch p{
    margin: 0 15px 0 0;
}