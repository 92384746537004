.sidebar{
    width: 200px;
    /* background: #fff; */
    min-height: 100vh;
    position: relative;
}

.sidebar-header{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    width: 100%;
    padding: 40px 0;
}
.sidebar-header img{
    width: 100px;
    height: 100%;
}

.sidebar ul {
    padding: 0 0 0 10px;
}

.sidebar ul li{
    list-style: none;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.sidebar ul li span{
    font-weight: 300;
    margin-left: 10px;
    font-size: 13px;
}

.sidebar ul li:hover{
    background: rgba(0,0,0,0.04);
}

.sidebar ul li:hover span{
    /* background: rgba(0,0,0,0.04) */
    /* font-weight: 700; */
}

.sidebar ul li.active{
    /* color: #00b9fb; */
    background: rgba(0,0,0,0.08);
}
.sidebar ul li.active span{
    font-weight: 700;
}