/* Filter */
.customers-filter {
    width: 90%;
    background: #fff;
    padding: 20px 20px 10px 20px;
    margin-bottom: 15px;
    border-radius: 5px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    position: relative;
    display: inline-flex;
    justify-content: center;
}

.customers-filter.empty {
    margin-bottom: 40px;
}

.customers-filter form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.customers-filter form .ant-form-item {
    margin-right: 15px !important;
}

.customers-filter form .field.search-input input {
    width: 300px !important;
}

.customers-filter form .ant-input-search-icon {
    cursor: default !important;
}

.customers-filter form .ant-input-search-icon:before {
    display: none;
}

.customers-filter form .ant-form-item button {
    /* width: 150px; */
    /* margin-top: 30px !important; */
    margin-right: 10px;
}

.customers-filter .searchButtons {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}