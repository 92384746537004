.header{
    width: 100%;
    padding: 30px 0 20px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.header-title{
    display: flex;
    align-items: center;
}

.header-title svg{
    margin-right: 10px;
}

.header-title p{
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}
.dropdown-user{
    cursor: pointer
}
.dropdown-user:hover{
    background: #0000;
}
.user-menu{
    display: flex;
    align-items: center;
}
.user-menu p {
    margin: 0 10px 0 10px
}


.header-menu{
    display: flex;
}

 
.menu-notifications{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.menu-notifications:hover{
    background: rgba(0,0,0,0.05);
} 
.dropdown-notificatons{ 
    width: 400px;
    /* max-height: 500px; */
    background: #fff;
    padding: 0 10px 0 10px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border-radius: 10px;
} 

.notifications-list{}

.notifications-list-item{
  border-radius: 5px;
  /* cursor: pointer */
}

/* .ant-list-items {
    margin: 0 10px 0 0;
} */

.notifications-list-item:hover{
    background: #ebf9fe;
}

.ant-list-item-action > li{
    font-size: 17px;
}

.ant-list-item-action{
    margin-left: 15px !important;
}

.notifications-list-item .ant-list-item-meta-avatar{
    margin: 10px 20px 0 10px;
    background: #0000000d;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding-top: 7px;
}

.demo-infinite-container {
    height: 300px;
    overflow: auto;
}

.demo-infinite-container  .loader{
    display: flex;
    margin: 11px auto;
    justify-content: center;
} 

.menu-notifications-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.menu-notifications-header .icon-notification{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 4px 0 0 0px;
    cursor: pointer;
    background: #dfdfe0;
}
.menu-notifications-header .icon-notification:hover{
    background: #d2d2d7;
}

.ant-list-item-meta-title{
    font-size: 12px;
}