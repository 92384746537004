html,
body {
  height: 100%;
  width: 100%;
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.page-content {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f3;
}

.page-content .content {
  width: 100%;
  padding: 0 50px 100px 25px;
  overflow-x: scroll;
}

.ant-tag {
  border-radius: 5px;
}

.ant-timeline-item-last>.ant-timeline-item-tail {
  display: block;
}

.ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.ant-modal-title {
  border-radius: 10px 10px 0 0;
}

.ant-result-icon {
  margin-bottom: 10px !important;
}

.tag.customer-list {
  width: 120px;
  text-align: center;
  font-size: 10px;
}

.tag.created {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #68b9ec;
}

.tag.interest {
  /*color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de; */
  color: #69cb3f;
  background: #e4ffc9;
  border-color: #69cb3f;
}

.tag.analyse,
.tag.analysis {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}

.tag.not-interested {
  color: #a8750f;
  background: #fff6c8;
  border-color: #c49808;
}

.tag.negotiation,
.tag.changing {
  color: #007472;
  background: #c9f8f5;
  border-color: #0fc7c5;
}



.tag.fail {
  color: #b4262e;
  background: #ffdeeb;
  border-color: #ff7d75;
}

.tag.visit {
  color: #e85d04;
  background: #ffe0cb;
  border-color: #ffb586;
}

.tag.return {
  color: #eb2f96;
  background: #ffe9f2;
  border-color: #ec74a9;
}

.tag.disease {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}

.tag.first-contact {
  background: #c3c3c3;
  border-color: #333;
}


span.created {
  color: #1890ff;
}

span.interest {
  /*color: #13c2c2;*/
  color: #5f813b;
}

span.not-interested {
  color: #85621c;
}

span.fail {
  color: #f5222d;
}

span.visit {
  color: #e85d04;
}

span.return {
  color: #eb2f96;
}

span.disease {
  color: #722ed1;
}

/* span.first-contact{
  color: #722ed1; 
} */


button {
  border-radius: 5px !important;
}

button.excel {
  background: #008000;
  color: #fff;
  border-color: #008000;
}

button.excel:hover,
button.excel:active,
button.excel:focus {
  background: #008000;
  color: #fff;
  border-color: #008000;
}

/* .customers-list .priority{
  position: absolute;
  margin-left: -25px;
  margin-top: 10px;
} */

.ant-btn-primary {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.ant-btn-link {
  color: var(--color-primary) !important;
}

.ant-btn-default {
  background-color: #f0f0f0 !important;
  border-color: #d9d9d9 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-primary) !important;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: var(--color-primary) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: var(--color-primary) !important;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: var(--color-primary) !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: var(--color-primary) !important;
}