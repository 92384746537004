/* Loaging */
.loading-wallets {
    width: 100%;
    height: 300px;
    background: #fff;
    border-radius: 10px;
    margin-top: 20px;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, .07);
}

.add-wallet {
    width: 240px;
    height: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed var(--color-primary);
    background: rgba(var(--primary), 0.06);
    color: var(--color-primary);
    border-radius: 10px;
    cursor: pointer;
    margin: 0 10px 20px 20px;
}

.add-wallet span {
    margin-left: 15px;
    font-weight: 700;
}

.add-wallet:hover {
    background: rgba(var(--primary), 0.09);
}


/* Containerr */
.wallets-container {
    max-width: 100%;
    border-radius: 5px;
}

.wallets-container .card {
    margin: 0 10px 20px 20px;
    border-radius: 10px;
    overflow: hidden;
}