.add-user {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--color-primary);
    background: rgba(var(--primary), 0.06);
    color: var(--color-primary);
    margin: 20px 0 30px 0;
    border-radius: 10px;
    cursor: pointer;
}

.add-user span {
    margin-left: 15px;
    font-weight: 700;
}

.add-user:hover {
    background: #cff3ff
}

.configs-container {
    background: #fff;
    border-radius: 10px;
    min-height: 500px;
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
    padding-top: 40px;
}

.ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-tab-btn,
.ant-tabs-tab-btn {
    font-weight: 700 !important;
}

.select_users_list {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}

.select_users_list .ant-input-affix-wrapper {
    border-radius: 7px;
}

.select_users_list .ant-input-affix-wrapper .ant-input-prefix {
    margin-right: 12px;
    opacity: 0.3;
}

.select_users_list ul {
    list-style: none;
    margin-top: 15px;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
    border: 1px solid #d9d9d9;
    border-radius: 5px
}

.select_users_list ul li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
}

.select_users_list ul li .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.select_users_list ul li.active {
    background: #ebfaff;
}

.select_users_list ul li.hidden {
    display: none;
}


/* 
.select_users_list ul li:first-child {
    border-radius: 5px 5px 0 0;
}
*/
.select_users_list ul li:last-child {
    border-bottom: 0px;
}

.select_users_list ul li .selector {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.select_users_list ul li .selector .icon {
    display: none;
}

.select_users_list ul li.active .selector {
    background: #1890ff;
    border-color: #1890ff;
}


.select_users_list ul li .user span {
    display: block;
    font-size: 10px;
    line-height: 15px;
}

.select_users_list ul li .user span.name {
    font-weight: 500;
    font-size: 14px
}

.select_users_list ul li .user .tag {
    justify-self: flex-end;
}