/* Loaging */
.loading-customers{
    width: 100%;
    height: 300px;
    background:#fff;
    border-radius: 10px;
    margin-top: 20px;
    -webkit-box-shadow: 2px 2px 2px 0 rgba(0,0,0,.07);
    box-shadow: 2px 2px 2px 0 rgba(0,0,0,.07);
}

/* Containerr */
.customers-container{
    width: 100%;
    min-width: 1300px;    
    border-radius: 5px;
    position: relative
}

.customers-totals{
    width: 100%;
    border-radius: 10px;
    margin: 30px 0 20px 0; 
    display: flex;
}

.customers-totals .status-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.customers-totals .item{
    margin: 10px;
    background: rgba(255, 255, 255, 0.6);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 0px;
}
.customers-totals .item:hover{
    background: #ebfaff;
}
.customers-totals .item .status-name{
    font-weight: 700;
    font-size: 12px;
}
.customers-totals .item .status-icon{
    width: 10px;
    height: 10px;
    background: #ebfaff;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
}
.customers-totals .item .status-number{
    font-style: italic;
    margin-left: 10px;
}


.customers-totals .item.first{
    cursor: inherit;
    margin-right: 20px;
    background: transparent;
}
.customers-totals .item.first .status-number{
    margin-left: 10px;
}
.customers-totals .item.first:hover{
    background: none;
}
.customers-totals .item.first:after{
    content: '';
    width: 1px;
    height: 15px;
    background:rgba(0,0,0,.2);
    position: absolute;
    right: -10px;
}



/* Pagination */
.customers-pagination{
    display: flex;
    margin-top: 25px;
}


