.add-buttons {
    display: flex;
    justify-content: space-between;
}

.add-customer {
    width: 49%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed var(--color-primary);
    background: rgba(var(--primary), 0.06);
    color: var(--color-primary);
    margin: 0px 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.add-customer span {
    margin-left: 15px;
    font-weight: 700;
}

.add-customer:hover {
    background: rgba(var(--primary), 0.09);
}


.import-customer {
    width: 49%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #52c41a;
    background: #f6ffed;
    color: #52c41a;
    margin: 0px 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}

.import-customer span {
    margin-left: 15px;
    font-weight: 700;
}

.import-customer:hover {
    background: #ebffd7;
}



.add-customer-step-1 form,
.add-customer-step-2 form,
.add-customer-step-3 form {
    margin: 20px 0 0 60px;
}

.add-customer-step-1 form .local .ant-form-item-control-input-content {
    display: flex;
}

.add-customer-form-footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.add-customer-form-footer button {
    margin: 0 10px;
}

.add-customer-form-footer button.ant-btn-primary {
    width: 130px;
}

.add-customer-form-footer button.green {
    background: #2dbf12;
    border-color: #30bf12;
}


/* Imoport */

.modalImportCustomer .ant-modal-close-x {
    display: none;
}

.modalImportCustomer.imported .ant-modal-close-x {
    display: block;
}

.import-loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 50px;
}

.import-loading .loading {
    width: 400px;
    display: block;
    margin: 0 auto;
}

.import-loading h3 {
    font-size: 16px;
    font-weight: 700;
}

.import-loading p {
    font-size: 13px;
    line-height: 16px;
    background: rgb(0 0 0 / 3%);
    width: 400px;
    padding: 10px 0;
    margin: 15px auto;
    border-radius: 10px;
}

.import-loading p small {
    font-size: 10px;
    color: #1b71b7;
}


.imported-customers .imported-list {
    max-height: 300px;
    overflow-y: scroll;
}

.imported-customers .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: rgba(var(--primary), 0.06);
    padding: 10px 20px;
    margin-bottom: 15px;
    align-items: center;
}

.imported-customers .item.header {
    background: rgb(0 0 0 / 3%);
    font-weight: 700;
    margin-bottom: 5px;
    border: 1px solid rgb(0 0 0 / 5%);
    height: 30px;
    font-size: 12px;
    align-items: center;
}

.imported-customers .item.header span {
    width: 100px;
}

.imported-customers .item .data {
    display: flex;
    align-items: center;
}

.imported-customers .item .data .company {
    width: 200px;
    display: inline-block;
    margin-right: 50px;
    font-weight: 500;
    font-size: 12px;
}

.imported-customers .item .tag {
    width: 100px;
    text-align: center;
    height: 25px;
}

.imported-customers .close-button {
    display: block;
    margin: 0 auto
}