/* List */
.goals-list {
    width: 90%;
    position: relative;
}

.goals-list .goal {
    display: flex;
    align-items: center;
    border: 1px solid #f1f1f1;
    background: #fff;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid rgb(0 0 0 / 8%)
}

.goals-list .goal-item {
    padding: 15px;
}

.goals-list .goal.active {
    background: #ebfaff;
    border: 1px solid #c9dcef;
}

.goals-list .goal.active:hover {
    background: #ebfaff;
}

.goals-list .goal:hover {
    /* background: #ebfaff; */
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 1px 2px 0px rgb(0 0 0 / 12%);
}

/* .goals-list .goal:hover{
    border-color: #00b9fb;
    background: #ebfaff;
} */

/* Header */
.goals-header {
    /* width: 90%; */
    display: flex;
    background-color: #e5e5e6;
    border-radius: 10px;
    padding: 0 0 13px 0;
    position: relative;
    border: 1px solid #ddd;
}

.goals-header-item {
    padding: 15px 15px 0 15px;
}

.goals-header-item span {
    font-weight: 700;
    font-size: 13px !important;
}


/* List and header widths */
.goals-header-item.select,
.goal-item.select {
    width: 40px;
    font-size: 12px;
    z-index: 1;
}

.goals-header-item.priority,
.goal-item.priority {
    width: 20px;
    font-size: 12px;
}

.goals-header-item.date,
.goal-item.date {
    width: 150px;
    font-size: 12px;
}

.goals-header-item.company,
.goal-item.company {
    width: 200px;
    font-size: 12px;
}

.goals-header-item.status,
.goal-item.status {
    width: 150px;
    margin-right: 5px;
    font-size: 12px;
}

.goals-header-item.state,
.goal-item.state {
    width: 200px;
    font-size: 12px;
}

.goals-header-item.proposal,
.goal-item.proposal {
    width: 90px;
    font-size: 11px;
    text-align: left;
}

.goals-header-item.plan,
.goal-item.plan {
    width: 150px;
    font-size: 12px;
}

.goals-header-item.responsible,
.goal-item.responsible {
    width: 100px;
    font-size: 12px;
}

.goals-header-item.origin,
.goal-item.origin,
.goals-header-item.sell,
.goal-item.sell {
    width: 120px;
    font-size: 12px;
}

.goal-item.plan span.plan-name {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.goal-item.plan span.plan-price {
    font-weight: bold;
}

.goal-item.responsible span.responsible-name {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.update-many-goals {
    /* margin: -10px auto 0 auto;
    height: 80px;
    background:#1890ff;
    border: 1px solid #ddd;
    bottom: 10px;
    z-index: 1;
    display: flex;
    align-items: center;
    border-radius: 0 0 10px 10px;
    transition: .2s; */
    height: 54px;
    background: #175b9a;
    border: 1px solid #ddd;
    bottom: 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: .2s;
    position: absolute;
    top: 69px;
    width: 100%;
}

.update-many-goals.specialist {
    top: 0;
}

.update-many-goals .info {
    display: flex;
    margin: 0 10px 0 50px;
    align-items: center;
    position: relative;
    color: #fff;
}

.update-many-goals .info .title {
    font-weight: 300;
}

.update-many-goals .info .number {
    font-weight: 700;
    color: #fff;
    margin-left: 5px;
    font-size: 20px;
}

.update-many-goals .info .number:after {
    content: '';
    width: 1px;
    height: 17px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -20px;
    top: 7px
}

.update-many-goals .ant-row {
    margin: 0px;
    margin: 0 30px;
    color: #fff
}

.update-many-goals .ant-row label {
    color: #fff !important;
}

.update-many-goals button {
    background: #096dd9;
    border-color: #096dd9;
}