.add-plan{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #00b9fb;
    background: #ebfaff;
    color: #00b9fb;
    margin: 10px 0 20px 0;
    border-radius: 10px;
    cursor: pointer;
}
.add-plan span{
    margin-left: 15px;
    font-weight: 700;
}
.add-plan:hover{
    background: #cff3ff
}